import React from 'react'
import './App.css';
import { Provider } from 'react-redux'
import Store from './Store'
import MainContainer from './components/MainContainer'

function App() {
    return (
        <Provider store={Store}>
            <MainContainer />
        </Provider>
    )
}

export default App